import React from 'react';
import './App.css';
import Home from './pages/Home';
import Project1 from './pages/Project1';
import Project2 from './pages/Project2';
import Project3 from './pages/Project3';
import ScrollToTop from './components/ScrollToTop';
import { FaGithub, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Route, Routes, Link } from "react-router-dom";

function App() {
  return (
    <>
      <body>
        <header>
          <nav className="navbar">
              <Link to="/">
                <h1 className="logo cspace">
                    Kristin Schaefer
                </h1>
              </Link>
              <div class="menu">
                  {/* <a href="resume.html" className="nav_item">CV</a> */}
                  <a href="https://www.instagram.com/kristinschaefer__/" className="nav_item cspace" target="blank">
                    <FaInstagram/>
                  </a>
                  <a href="https://www.linkedin.com/in/kristin-schaefer-a3b602177/" className="nav_item cspace" target="blank">
                    <FaLinkedin/>
                  </a>
                  <a href="https://github.com/schaefkr" className="nav_item cspace" target="blank">
                    <FaGithub/>
                  </a>
              </div>
              <div className="mobile_menu">
                  <span className="bar"></span>
                  <span className="bar"></span>
              </div>
          </nav>
        </header>

        <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home />}/>
              <Route path="/project1" element={<Project1 />}/>
              <Route path="/project2" element={<Project2 />}/>
              <Route path="/project3" element={<Project3 />}/>
            </Routes>
        </ScrollToTop>

        <footer id="footer">
            <a href="mailto:kristinlschaefer@gmail.com">kristinlschaefer@gmail.com</a>
        </footer>
      </body>
    </>
  );
}

export default App;
