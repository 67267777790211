import React from 'react';
import idontdrive1 from '../assets/I-Dont-Drive-1.png';
import idontdrive2 from '../assets/I-Dont-Drive-2.png';
import idontdrive3 from '../assets/I-Dont-Drive-3.png';
import SimpleImageSlider from "react-simple-image-slider";
import useResizeObserver from "use-resize-observer";

const Project3 = () => {
    const images = [
        { url: idontdrive1 },
        { url: idontdrive2 },
        { url: idontdrive3 },
    ];
    
    const { ref, width = 1, height = 1 } = useResizeObserver();

    return(
        <main>
            <section id="hero">
            <h1 class="hero_text">I Don't Drive</h1>
            <p class="hero_p">
                Anxiety of driving turned Escher-esque urban landscape. <br />
                Featured at Fresh Hydrangea. <br />
                Created with Rhino 3d, Grasshopper and Python. <br />
            </p>
            </section>
            <section id="portfolio">
            <div class="portfolio_slideshow_container">
                <div ref={ref} class="portfolio_slideshow_container_item">
                    <SimpleImageSlider
                        width={width}
                        height={height}
                        images={images}
                        showBullets={false}
                        showNavs={false}
                        autoPlay={true}
                    />
                </div>
            </div>
            </section>
        </main>
    );
};

export default Project3;