import React from "react";

const TableRow = ({ data }) => {
    return (
        <tr class="portfolio_table_row">
            {data.map((item) => {
                return <td key={item}>{item}</td>;
            })}
        </tr>
    );
};

export default TableRow;