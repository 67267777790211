import React from 'react';
import p1main from '../assets/project-1-main-enhanced.jpeg';
import p2main from '../assets/project-2-main.png';
import p3main from '../assets/project-3-main.png';
import FadeInWhenVisible from '../components/FadeInWhenVisible';
import { Link } from "react-router-dom";

const Home = () => {
    return (
        <main>
            <section id="hero">
            <h1 class="hero_text">
                Kristin Schaefer is an Architect turned <br /> 
                Software Engineer. She enjoys designing <br /> 
                and developing web and mobile apps as<br /> 
                well as making experimental digital art.<br /> 
            </h1>
            <p class="hero_p">A selection of coding projects from 2019-present.</p>
            </section>
            <section id="portfolio">

                <div class="portfolio_item">
                    <div class="portfolio_img">
                        <Link to="/project1">
                            <img
                                src={ p1main }
                                alt="Project 1"
                            />
                        </Link>
                    </div>
                    <div class="portfolio_content">
                        <h2 class="portfolio_title">point cloud cities</h2>
                        <p class="portfolio_description">USGS lidar data of Seattle transformed into pixellated cityscapes. </p>
                    </div>
                </div>

                <div class="portfolio_item">
                    <FadeInWhenVisible>
                        <div class="portfolio_img">
                            <Link to="/project2">
                                <img
                                    src={ p2main }
                                    alt="Project 2"
                                />
                            </Link>
                        </div>
                    </FadeInWhenVisible>
                    <div class="portfolio_content">
                        <h2 class="portfolio_title">the bike kollective</h2>
                        <p class="portfolio_description">A cross-platform mobile app for Oregon State University capstone project. Team members Anita Ly, Jon Ramm, and Kristin Schaefer.</p>
                    </div>
                </div>

                <div class="portfolio_item">
                    <FadeInWhenVisible>
                        <div class="portfolio_img">
                            <Link to="/project3">
                                <img
                                    src={ p3main }
                                    alt="Project 3"
                                />
                            </Link>
                        </div>
                    </FadeInWhenVisible>
                    <div class="portfolio_content">
                        <h2 class="portfolio_title">I Don't Drive</h2>
                        <p class="portfolio_description">Anxiety of driving turned Escher-esque urban landscape. Featured at Fresh Hydrangea.</p>
                    </div>
                </div>

            </section>

        </main>
    );
};

export default Home;