import React from 'react';
import p2main from '../assets/project-2-main.png';
import p2other2 from '../assets/project-2-other-2.png';
import p2demo1iOS from '../assets/project-2-demo-2.mp4';
import p2demo1Chrome from '../assets/project-2-demo-2.webm';
import Table from "../components/Table";
import FadeInWhenVisible from '../components/FadeInWhenVisible';
import { browserName, CustomView } from 'react-device-detect';

const Project2 = () => {
    const theadData = ["Technology", "Application"];

    const tbodyData = [
        {
            id: "1",
            items: ["Firebase (Functions, Firestore, Authentication)", "Backend"],
        },
        {
            id: "2",
            items: ["GCP Logs Explorer", "Accessing Production Logs"],
        },
        {
            id: "3",
            items: ["GCP API & Services", "Managing Client IDs, OAuth Consent Screen, API Keys"],
        },
        {
            id: "4",
            items: ["React Native", "Frontend"],
        },
        {
            id: "6",
            items: ["Expo Go, SDK, Expo Application Services (EAS)", "Testing, Setting Up Authentication, Running Builds"],
        },
        {
            id: "7",
            items: ["XCode (iOS Simulator), Android Studio (Android Emulator)", "Testing, Debugging Builds"],
        },
        {
            id: "8",
            items: ["Jira", "Project Management"],
        },
    ];

    return(
        <main>
            <section id="hero">
            <h1 class="hero_text">The Bike Kollective</h1>
            <p class="hero_p">
                A cross-platform mobile app for Oregon State University capstone project.  <br/> 
                Team members Anita Ly, Jon Ramm, and Kristin Schaefer. 
            </p>
            <a href="https://github.com/jonramm/Bike_Kollective" className="hero_link cspace" target="blank">
                <br/>  
                Github Repository
            </a>
            </section>

            <section id="portfolio">
            <div class="portfolio_item">

                <div class="portfolio_img">
                    <img
                        src={ p2main }
                        alt="Project 2"
                    />
                </div>

                <div class="portfolio_content">
                    <p class="portfolio_paragraph">
                    The Bike Kollective is a mobile app which allows users to check out and ride a nearby bike that is a part of the
                    Bike Kollective network. Users can register for a free account with a username and password or with Google Authentication. 
                    Once logged in, users can view network bikes within 3000 meters of their current location in a map view 
                    (default for Android is Google Maps, iOS is Apple Maps) or a list view which can be filtered by tags such as Commuter, 
                    Gravel, or Single-Speed. 
                    </p>
                </div>

                <FadeInWhenVisible>
                    <div class="portfolio_img">
                        <img
                            src={ p2other2 }
                            alt="Project 2"
                        />
                    </div>
                </FadeInWhenVisible>

                <div class="portfolio_content">
                    <p class="portfolio_paragraph">
                    Once Start Trip is tapped the user will be presented with the lock combination, the time remaining 
                    in the ride, and a button to end the ride. The user will be reminded to return a checked out bike before their ride time has 
                    elapsed. If the bike is out longer than 24 hours, then the user will be locked out of their account. Once End Trip is tapped 
                    the user can rate the ride and report issues that may have arisen. The Add tab allows users to fill out a form with
                    bike details, select applicable tags, choose a photo from the library of their mobile device or take one with the camera of their 
                    mobile device, and sign a release waiver. Users may only check out one bike at a time and are strongly encouraged to adhere to 
                    the honor system so all users can benefit from the network.  <br/>  
                    <br/>
                    Above - the UI workflow for the app. <br/>
                    Below - a demonstration of a standard workflow.
                    </p>
                </div>

                <FadeInWhenVisible>
                    <CustomView condition={browserName !== "Safari"}>
                        <div class="portfolio_content">
                            <video loop muted autoPlay class="portfolio_video">
                                <source src={ p2demo1Chrome } />
                            </video>
                        </div>
                    </CustomView>
                </FadeInWhenVisible>

                <FadeInWhenVisible>
                    <CustomView condition={browserName === "Safari"}>
                        <div class="portfolio_content">
                            <video loop muted autoPlay class="portfolio_video">
                                <source src={ p2demo1iOS } />
                            </video>
                        </div>
                    </CustomView>
                </FadeInWhenVisible>

                <div class="portfolio_content">
                    <p class="portfolio_paragraph">
                    The app consists of a React frontend and a Firebase backend. 
                    Expo Go, XCode iOS simulator, and Android Studio Android Emulator were used for testing.
                    </p>
                </div>

                <FadeInWhenVisible>
                    <div class="portfolio_img">
                        <Table theadData={theadData} tbodyData={tbodyData} customClass={"portfolio_table"}/>
                    </div>
                </FadeInWhenVisible>

            </div>
            </section>
        </main>
    );
};

export default Project2;