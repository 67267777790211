import React from 'react';
import { motion } from "framer-motion";

function FadeInWhenVisible({ children }) {
    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.5 }}
            variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: "10%" }
            }}
        >
            {children}
        </motion.div>
    );
}

export default FadeInWhenVisible;