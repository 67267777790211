import React from 'react';
import p1main from '../assets/project-1-main-enhanced.jpeg';
import p1other2 from '../assets/project-1-other-2-enhanced.jpeg';
import p1demo1 from '../assets/project-1-demo-1.mp4';
import FadeInWhenVisible from '../components/FadeInWhenVisible';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { codeBlock1 } from '../assets/project-1-code-str';

const Project1 = () => {
    return(
        <main>
            <section id="hero">
            <h1 class="hero_text">Point Cloud Cities</h1>
            <p class="hero_p">USGS lidar data of Seattle transformed into pixellated cityscapes.</p>
            <a href="https://github.com/schaefkr/LAS_to_Rhino_3DM" className="hero_link cspace" target="blank">
                <br/>  
                Github Repository
            </a>
            </section>

            <section id="portfolio">

            <div class="portfolio_item">

                <div class="portfolio_img">
                    <img
                        src={ p1main }
                        alt="Project 1"
                    />
                </div>

                <div class="portfolio_content">
                    <p class="portfolio_paragraph">
                    The USGS has an extensive collection of lidar point cloud data available for free on its website. 
                    Using this data, I wrote a Python script to split the data into sub point clouds 
                    based on .las classification codes. I then wrote each point cloud to a Rhino .3dm file. 
                    Within Rhino, I then used Grasshopper to pixellate each point cloud. Hence, Point Cloud City.
                    </p>
                </div>

                <div class="portfolio_content">
                    <video loop muted autoPlay class="portfolio_video" id="portfolio_video_blend">
                        <source src={ p1demo1 } type="video/mp4"/>
                    </video>
                </div>

                <div class="portfolio_content">
                    <p class="portfolio_paragraph">
                    Below the Python script for writing a .las file to a Rhino .3dm file with sub point clouds 
                    organized by classification code.
                    </p>
                </div>

                <FadeInWhenVisible>
                    <div class="portfolio_content">
                        <SyntaxHighlighter 
                            language="python" 
                            style={ docco } 
                            showLineNumbers
                            wrapLongLines
                            customStyle={{
                                backgroundColor: "transparent",
                            }}
                        >
                            { codeBlock1 }
                        </SyntaxHighlighter>
                    </div>
                </FadeInWhenVisible>

                <FadeInWhenVisible>
                    <div class="portfolio_img">
                        <img
                            src={ p1other2 }
                            alt="Project 1"
                        />
                    </div>
                </FadeInWhenVisible>

            </div>

            </section>

        </main>
    );
};

export default Project1;